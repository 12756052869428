/* eslint no-param-reassign: "off" */
import axios from 'axios';
import { get, toNumber, isNil } from 'lodash';
import * as Sentry from '@sentry/react';

import ReactGA from 'react-ga';

import { parseJwt } from './helpers';
import Apollo from './apollo';

export const ROLE_ADMIN = 'Admin';
export const ROLE_CONTENT_MANAGER = 'Content Manager';
export const PLATFORM_ADMIN = 'PLATFORM_ADMIN';
export const TENANT_ADMIN = 'TENANT_ADMIN';
export const RECRUITER = 'RECRUITER';
export const CONTENT_MANAGER = 'CONTENT_MANAGER';
export const EVALUATOR = 'EVALUATOR';

const roleMap = ['PLATFORM_ADMIN', 'TENANT_ADMIN', 'MASTER_RECRUITER', 'RECRUITER', 'CONTENT_MANAGER', 'EVALUATOR'];
class Auth {
  constructor() {
    this.expires = null;
    if (!Auth.instance) {
      Auth.instance = this;
    }
  }

  get isLoggedIn() {
    return !!this.graphToken;
  }

  get hasuraClaims() {
    return get(this.user, 'https://hasura.io/jwt/claims', {});
  }

  get stored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.lg') || '{}');
    } catch (e) {
      Sentry.captureException(e);
    }
    return st;
  }

  get graphToken() {
    return get(this.stored, 'access_token', null);
  }

  get user() {
    return get(this.stored, 'user', null);
  }

  get organization() {
    return this.hasuraClaims['X-Hasura-Tenant-Id'] || 0;
  }

  get id() {
    return toNumber(get(this.stored, 'user.uid', null));
  }

  get roles() {
    return get(this.hasuraClaims, 'X-Hasura-Allowed-Roles', []);
  }

  async client(role) {
    const roles = this.roles.sort((a, b) => roleMap.indexOf(a) - roleMap.indexOf(b));
    // const roles_list = get(this.stored, "user['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles']", null);
    let role_value = null;
    if (role && roles && roles.length && roles.includes(role)) {
      role_value = role;
    }
    return Apollo.createClient({ graphToken: this.graphToken, role: role_value, refresh: true });
  }

  async clientNoAuth() {
    return Apollo.createClient({ refresh: true, noAuth: true });
  }

  removeClient() {
    Apollo.removeClient();
  }

  async createClient(errorCallback) {
    return Apollo.createClient({
      graphToken: this.graphToken,
      errorCallback,
      role: get(this.roles, '0'),
    });
  }

  async processToken(access_token) {
    await this.logout();
    const user = parseJwt(access_token);
    const rest_token = user['rest-token'];
    const role = user?.['https://hasura.io/jwt/claims']?.['X-Hasura-Default-Role'];
    const roles = user?.['https://hasura.io/jwt/claims']?.['X-Hasura-Allowed-Roles'];
    localStorage.setItem('tv.lg', JSON.stringify({ access_token, user, rest_token, role, roles }));
    this.expires = user.exp;
    Sentry.setUser({ id: user.uid });
    ReactGA.set({ userId: user.uid });
    return user;
  }

  async logout() {
    localStorage.removeItem('tv.lg');
    localStorage.removeItem('adtv.accessToken');
    await Apollo.clearCache();
    await Apollo.removeClient();
  }

  async authenticate({ username, password }) {
    if (isNil(username) || isNil(password)) return Promise.reject(new Error('Invalid username/password'));
    const { data } = await axios.post('/api/auth', { username, password });
    if (!data) throw new Error('Invalid username/password');
    const access_token = get(data, 'access_token');
    await this.processToken(access_token);
    return data;
  }
}

export const instance = new Auth();

export default instance;
